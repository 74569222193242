<template>
    <div>
        <img class="example-headImg" src="../assets/image/selected/08-2banner.png"/>
        <div class="head-explain">
            <img class="head-img-logo" src="../assets/image/selected/08-2iCRM .png" style="width:10.2rem;height: 5.5rem;"/>
            <div class="head-explain-title">销售运营管理</div>
            <div class="head-explain-content">IQVIA iCRM 是专为生命科学行业设计打造的</div>
            <div class="head-explain-content" style="margin-top:-3rem">销售运营管理解决方案</div>
        </div>
        <div class="container-1">
            <div class="saled-box1">
                <div class="title1">
                    IQVIA iCRM 是专为生命科学行业设计打造的销售运营管理解决方案。支持多部门协作及信息共享，多渠道触达客户。<br/>
                    助力企业快速适应不断变化的商业需求，实现新数字化时代下的客户触达和销售运营管理。
                </div>
            </div>
        </div>
        <div class="container-2" style="height:90rem">
            <div class="saled-box2">
                <div class="container">
                    <div class="card">
                        <img src="../assets/image/selected/08-2多部门协作.png"/>
                        <div class="title">多部门协作<br/>
                            多渠道触达</div>
                    </div>
                    <div class="card">
                        <img src="../assets/image/selected/08-2合规辅助.png"/>
                        <div class="title">合规辅助甄别帮助企业<br/> 获取更真实客户反馈</div>
                    </div>
                    <div class="card">
                        <img src="../assets/image/selected/08-2数据驱动.png"/>
                        <div class="title">数据驱动，智能推荐<br/>
                            实时监控，优化行为</div>
                    </div>
                </div>
                <img class="saled-box2-img" src="../assets/image/selected/08-2中图.png"/>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.saled-box1{
    width: 139.7rem;
    height: 8.8rem;
    margin: auto;
    margin-top: 4.4rem;
    margin-bottom: 2rem;
    .title1{
        width: 139.7rem;
        height: 8.8rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #333333;
        line-height: 3rem;
        margin-top: 2rem;
        
    }
}
.saled-box2{
    width: 86rem;
    margin: auto;
    .container{
        width: 86rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card{
            width: 26rem;
            display: flex;
            margin-top: 5rem;
            margin-bottom: 5.6rem;
            img{
                width: 7rem;
                height: 7rem;
                float: left;
            }
            .title{
                margin-left: 2.1rem;
                width: 18rem;
                font-size: 1.4rem;
                font-family: 'CN_Normal';
                text-align: left;
                margin-top: 1.5rem;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }
    .saled-box2-img{
        width: 74.6rem;
        height: 60.9rem;
    }
}
</style>